import React from "react";
import "../../../shared/common/Style.css";
import bar_chart from "../../../../assets/website/alia_icon_chart.svg";
import checklist from "../../../../assets/website/alia_icon_checklist.svg";
import profile from "../../../../assets/website/profile_icon_invert.svg";
import progress from "../../../../assets/website/progress_line.svg";
import right_step_arrow from "../../../../assets/website/right_step_arrow.svg";
import left_step_arrow from "../../../../assets/website/left_step_arrow.svg";
import { useNavigate } from "react-router-dom";

const StepFlow = () => {
    const navigate = useNavigate();
    const steps = [
        {
            icon: bar_chart,
            title: "STEP 1: APPLY FOR AN ALIA ACCOUNT",
            content:
                "The journey to your goal score starts when you apply for an Alia account. Once you're approved, you'll be able to create an account and start studying.",
        },
        {
            icon: checklist,
            title: "STEP 2: TAKE A DIAGNOSTIC AND GET YOUR PERSONALIZED ROADMAP",
            content:
                "Start by taking our comprehensive diagnostic test. From these insights, Alia crafts a personalized roadmap tailored to your needs, setting you on a clear path to SAT success.",
        },
        {
            icon: profile,
            title: "STEP 3: UPGRADE TO PREMIUM AND MEET WITH YOUR EXPERT TUTOR",
            content:
                "Unlock premium features for access to adaptive tests, personalized modules, and expert tutor support. Stay on track and progress with regular check-ins with your tutor to ensure your success.",
        },
        {
            icon: progress,
            title: "STEP 4: HIT YOUR GOAL SCORE!",
            content:
                "With Alia's structured approach, personalized attention, and your commitment, reaching your goal score becomes an achievable reality. Celebrate your success and start applying to your dream college!",
        },
    ];

    const STEP_HEIGHT = 262; // Height of each step in pixels
    const STEP_OFFSET = 131;

    return (
        <div className="as-container" style={{ backgroundColor: "#FFF" }}>
            <div className="as-content" style={{ color: "#016dea" }}>
                <div className="as-title" style={{ color: "#016dea" }}>
                    Why Study With Alia?
                </div>
                <div className="sf-flex-container">
                    <div className="sf-arrow-column">
                        {steps.map(
                            (_, index) =>
                                index % 2 !== 1 &&
                                index > 0 && (
                                    <div
                                        key={index}
                                        className="sf-arrow-wrapper"
                                        style={{
                                            position: "absolute",
                                            height: `${STEP_HEIGHT}px`,
                                            top: `${STEP_OFFSET + (index - 1) * STEP_HEIGHT}px`,
                                            left: 0,
                                            right: 0,
                                        }}
                                    >
                                        <img
                                            style={{
                                                height: `${STEP_HEIGHT}px`,
                                                width: "100px",
                                            }}
                                            src={left_step_arrow}
                                            alt="Step arrow"
                                        />
                                    </div>
                                ),
                        )}
                    </div>
                    <div className="sf-steps-column">
                        {steps.map((step, index) => (
                            <div key={index} className="sf-step-container">
                                <div className="sf-rectangle">
                                    <div className="sf-task-header-group">
                                        <div
                                            className="feature-item"
                                            style={{ marginRight: "15px" }}
                                        >
                                            <img
                                                style={{
                                                    width: "35px",
                                                    height: "35px",
                                                    padding: "5px",
                                                    backgroundColor: "#FFF",
                                                    objectFit: "contain",
                                                }}
                                                src={step.icon}
                                            />
                                        </div>
                                        <div className="sf-step-container-title">
                                            {step.title}
                                        </div>
                                    </div>
                                </div>
                                <div className="sf-step-content-container">
                                    <div className="sf-step-content">
                                        {step.content}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="sf-arrow-column">
                        {steps.map(
                            (_, index) =>
                                index % 2 !== 0 &&
                                index < steps.length && (
                                    <div
                                        key={index}
                                        className="sf-arrow-wrapper"
                                        style={{
                                            position: "absolute",
                                            height: `${STEP_HEIGHT}px`,
                                            top: `${STEP_OFFSET + (index - 1) * STEP_HEIGHT}px`,
                                            left: 0,
                                            right: 0,
                                        }}
                                    >
                                        <img
                                            style={{
                                                height: `${STEP_HEIGHT}px`,
                                                width: "100px",
                                            }}
                                            src={right_step_arrow}
                                            alt="Step arrow"
                                        />
                                    </div>
                                ),
                        )}
                    </div>
                </div>
                <button
                    className="apply-button"
                    onClick={() => navigate("/apply")}
                >
                    APPLY NOW
                </button>
            </div>
        </div>
    );
};

export default StepFlow;
