import alia_logo_blue from "../../../../assets/alia_logo_blue.svg";
import React from "react";
import { useNavigate } from "react-router-dom";

const NavBar = () => {
    const navigate = useNavigate();

    const login = () => {
        const loginUrl = new URL("https://sat.aliaeducation.com");
        window.location.href = loginUrl.toString();
    };

    return (
        <div className="ws-nav-container">
            <div className="ws-logo-container">
                <img
                    src={alia_logo_blue}
                    style={{
                        maxWidth: "100px",
                        maxHeight: "50px",
                        cursor: "pointer",
                    }}
                    alt="Alia Logo"
                    className="ws-logo"
                    onClick={() => navigate("/")}
                />
            </div>
            <div className="ws-nav-buttons">
                <button
                    className="ws-nav-button"
                    style={{ minWidth: "150px" }}
                    onClick={() => navigate("/how-it-works")}
                >
                    HOW IT WORKS
                </button>
                <button
                    className="ws-nav-button"
                    style={{ minWidth: "100px" }}
                    onClick={() => navigate("/pricing")}
                >
                    PRICING
                </button>
                <button
                    className="ws-nav-button"
                    style={{ minWidth: "110px" }}
                    onClick={() => login()}
                >
                    LOG IN
                </button>
                <button
                    className="ws-nav-button apply"
                    onClick={() => navigate("/apply")}
                >
                    APPLY NOW
                </button>
            </div>
        </div>
    );
};

export default NavBar;
