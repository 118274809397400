// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subsection {
    margin-left: 10px;
}

.topic {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    margin-left: 40px;
}

.topic input[type="checkbox"] {
    margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/practice/generate/GenerateProblemSets.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".subsection {\n    margin-left: 10px;\n}\n\n.topic {\n    display: flex;\n    align-items: center;\n    margin-bottom: 5px;\n    margin-left: 40px;\n}\n\n.topic input[type=\"checkbox\"] {\n    margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
