import React from "react";
import "./Home.css";
import "./sections/Hero.css";
import TopBanner from "./sections/TopBanner";
import Advantage from "./sections/Advantage";
import Exclusive from "./sections/Exclusive";
import Everything from "./sections/Everything";
import FAQ from "./sections/FAQ";
import Apply from "./sections/Apply";
import NavBar from "./sections/NavBar";
import Features from "./sections/Features";
import Hero from "./sections/Hero";
import BottomBar from "./sections/BottomBar";
import Reviews from "./sections/Reviews";
import ComparisonTable from "./sections/ComparisonTable";

const DesktopHomepage = () => {
    return (
        <div className="ws-home-page">
            <TopBanner />
            <NavBar />
            <Hero />
            <Features />
            <Advantage />
            <ComparisonTable />
            <Reviews />
            <Exclusive />
            <Everything />
            <FAQ />
            <Apply />
            <BottomBar />
        </div>
    );
};

export default DesktopHomepage;
