import React from "react";
import "./Home.css";
import "./sections/Hero.css";
import TopBanner from "./sections/TopBanner";
import NavBar from "./sections/NavBar";
import BottomBar from "./sections/BottomBar";
import StepFlow from "./sections/StepFlow";

const HowItWorks = () => {
    return (
        <div className="ws-home-page">
            <TopBanner />
            <NavBar />
            <StepFlow />
            <BottomBar />
        </div>
    );
};

export default HowItWorks;
