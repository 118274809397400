import React, { useState } from "react";
import "../Home.css";
import { ChevronDown, ChevronUp } from "lucide-react";

const FAQ = () => {
    const [openDropdown, setOpenDropdown] = useState(null);

    const toggleDropdown = (index) => {
        setOpenDropdown(openDropdown === index ? null : index);
    };

    const faqItems = [
        {
            title: "WHY DOES THE SAT MATTER?",
            content:
                "The SAT is returning as a major differentiator in college applications. High score significantly boost acceptance rates at any college.",
        },
        {
            title: "WHAT MAKES ALIA DIFFERENT?",
            content: "Lorem ipsum.",
        },
        {
            title: "HOW DO I GET STARTED?",
            content: "Lorem ipsum.",
        },
    ];

    return (
        <div className="as-container">
            <div className="as-content">
                <div className="as-title">FREQUENTLY ASKED QUESTIONS</div>
                <div
                    className="as-right-container"
                    style={{ width: "60%", paddingTop: "20px" }}
                >
                    {faqItems.map((item, index) => (
                        <div key={index} className="as-dropdown">
                            <div
                                className="as-dropdown-header"
                                onClick={() => toggleDropdown(index)}
                            >
                                <div className="as-dropdown-text">
                                    {item.title}
                                </div>
                                {openDropdown === index ? (
                                    <ChevronUp size={32} color="#EC5725" />
                                ) : (
                                    <ChevronDown size={32} color="#FFAB24" />
                                )}
                            </div>
                            {openDropdown === index && (
                                <div className="as-text">{item.content}</div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FAQ;
