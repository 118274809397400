// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Calculator.css */
.calculator-container {
    position: fixed; /* Changed from relative to fixed */
    z-index: 1000;
    background-color: white;
    border: 1px solid #ccc;
}

.close-button {
    position: absolute;
    top: -20px;
    right: 0;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
}

.drag-handle {
    background-color: #007bff; /* Match the top bar color for consistency */
    padding: 5px;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    cursor: move; /* Cursor indicates movability */
    border-bottom: 1px solid #ccc; /* A subtle separator */
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/problem_console/header/Calculator.css"],"names":[],"mappings":"AAAA,kCAAkC;AAClC;IACI,eAAe,EAAE,mCAAmC;IACpD,aAAa;IACb,uBAAuB;IACvB,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,QAAQ;IACR,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB,EAAE,4CAA4C;IACvE,YAAY;IACZ,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,YAAY,EAAE,gCAAgC;IAC9C,6BAA6B,EAAE,uBAAuB;AAC1D","sourcesContent":["/* src/components/Calculator.css */\n.calculator-container {\n    position: fixed; /* Changed from relative to fixed */\n    z-index: 1000;\n    background-color: white;\n    border: 1px solid #ccc;\n}\n\n.close-button {\n    position: absolute;\n    top: -20px;\n    right: 0;\n    border: none;\n    cursor: pointer;\n    border-radius: 50%;\n    width: 30px;\n    height: 30px;\n    line-height: 30px;\n    text-align: center;\n}\n\n.drag-handle {\n    background-color: #007bff; /* Match the top bar color for consistency */\n    padding: 5px;\n    background: #007bff;\n    color: #fff;\n    border: none;\n    border-radius: 5px;\n    font-weight: bold;\n    cursor: pointer;\n    text-align: center;\n    cursor: move; /* Cursor indicates movability */\n    border-bottom: 1px solid #ccc; /* A subtle separator */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
