// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.step-modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
}

.step-modal-content {
    position: relative; /* Needed for absolute positioning of children */
    background: white;
    /*max-height: 55vh; !* 80% of the viewport height *!*/
    overflow-y: auto; /* Enable vertical scrolling */
    padding: 20px;
    border-radius: 35px;
    z-index: 2001;
    color: #2d2d2d;
    width: 700px;
    font-family: "DM Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; /* 140% */
    letter-spacing: 0.15px;
    align-items: center;
    border: 2px solid #016dea;
    /*box-sizing: border-box;*/
}

/* Hide scrollbar for Webkit browsers (Chrome, Safari, Edge) */
.step-modal-content::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE and Edge */
.step-modal-content {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/popups/JourneyStepModal.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,0CAA0C;IAC1C,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,kBAAkB,EAAE,gDAAgD;IACpE,iBAAiB;IACjB,qDAAqD;IACrD,gBAAgB,EAAE,8BAA8B;IAChD,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,cAAc;IACd,YAAY;IACZ,sBAAsB;IACtB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB,EAAE,SAAS;IAC5B,sBAAsB;IACtB,mBAAmB;IACnB,yBAAyB;IACzB,0BAA0B;AAC9B;;AAEA,8DAA8D;AAC9D;IACI,aAAa;AACjB;;AAEA,mCAAmC;AACnC;IACI,wBAAwB,EAAE,0BAA0B;IACpD,qBAAqB,EAAE,YAAY;AACvC","sourcesContent":[".step-modal-backdrop {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(255, 255, 255, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 2000;\n}\n\n.step-modal-content {\n    position: relative; /* Needed for absolute positioning of children */\n    background: white;\n    /*max-height: 55vh; !* 80% of the viewport height *!*/\n    overflow-y: auto; /* Enable vertical scrolling */\n    padding: 20px;\n    border-radius: 35px;\n    z-index: 2001;\n    color: #2d2d2d;\n    width: 700px;\n    font-family: \"DM Sans\";\n    font-size: 15px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 21px; /* 140% */\n    letter-spacing: 0.15px;\n    align-items: center;\n    border: 2px solid #016dea;\n    /*box-sizing: border-box;*/\n}\n\n/* Hide scrollbar for Webkit browsers (Chrome, Safari, Edge) */\n.step-modal-content::-webkit-scrollbar {\n    display: none;\n}\n\n/* Hide scrollbar for IE and Edge */\n.step-modal-content {\n    -ms-overflow-style: none; /* Internet Explorer 10+ */\n    scrollbar-width: none; /* Firefox */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
