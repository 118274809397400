import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import Website from "./Website";
import App from "./App";

// const isMainDomain = () => window.location.hostname === 'aliaeducation.com';
const isMainDomain = () =>
    window.location.pathname === "/blog" ||
    window.location.pathname === "/how-it-works" ||
    window.location.pathname === "/pricing";

// Global error handler
const errorHandler = (error) => {
    console.error("Uncaught error:", error);
    if (window.location.pathname !== "/error") {
        try {
            window.location.href = "/error";
        } catch (redirectError) {
            console.error("Failed to redirect to error page:", redirectError);
            alert("An unexpected error occurred. Please refresh the page.");
        }
    } else {
        // We're already on the error page and another error occurred
        console.error("Error occurred on error page:", error);
        alert("An unexpected error occurred. Please refresh the page.");
    }
};

// Handle runtime errors
window.addEventListener("error", errorHandler);

// Handle unhandled promise rejections
window.addEventListener("unhandledrejection", (event) => {
    errorHandler(event.reason);
});

const root = createRoot(document.getElementById("root"));

root.render(
    <BrowserRouter>
        {isMainDomain() ? (
            <Website />
        ) : (
            <Auth0Provider
                domain="auth0.platform.aliaeducation.com"
                clientId="TQn5yos8s6SeNwFtOydPoz5PS4V21oHW"
                redirectUri={window.location.origin}
            >
                <App />
            </Auth0Provider>
        )}
    </BrowserRouter>,
);
