import "../Home.css";
import React from "react";
import { useNavigate } from "react-router-dom";

const Exclusive = () => {
    const navigate = useNavigate();
    return (
        <div className="as-container">
            <div className="as-content">
                <div className="as-title" style={{ paddingBottom: "40px" }}>
                    An Exclusive Platform for Motivated Students
                </div>
                <div
                    className="as-text"
                    style={{
                        width: "60%",
                        textAlign: "center",
                        paddingLeft: "0px",
                        paddingBottom: "50px",
                    }}
                >
                    At Alia, we pride ourselves on being a referral and
                    invite-only organization, committed to delivering real
                    results. Our approach is designed for those who are
                    genuinely ready to invest in their future and embrace the
                    journey of learning.
                </div>
                <div className="info-boxes">
                    <div className="info-box">
                        <div className="info-header">BY INVITATION ONLY</div>
                        <div className="info-text">
                            Our exclusive, application-only process ensures that
                            each student is committed to achieving their goals
                            with perseverance and passion. We welcome those who
                            are eager to be part of a supportive and results-
                            driven community.
                        </div>
                    </div>
                    <div className="info-box">
                        <div className="info-header">
                            STUDENT SELECTION CRITERIA
                        </div>
                        <div className="info-text">
                            Our selection isn&apos;t based on your current
                            scores or past performances; rather, we value your
                            dedication, work ethic, and readiness to engage
                            actively in our comprehensive learning process.
                        </div>
                    </div>
                    <div className="info-box">
                        <div className="info-header">NO SCORE REQUIREMENTS</div>
                        <div className="info-text">
                            Whether you&apos;re starting from scratch or looking
                            to improve, we welcome students who are willing to
                            show up and put in the effort. At Alia, it&apos;s
                            your commitment and willingness to learn that matter
                            the most.
                        </div>
                    </div>
                </div>
                <button
                    className="apply-button"
                    onClick={() => navigate("/apply")}
                >
                    APPLY NOW
                </button>
            </div>
        </div>
    );
};

export default Exclusive;
