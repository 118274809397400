import { Route, Routes } from "react-router-dom";
import DesktopHomepage from "./components/website/web/Home";
import HowItWorks from "./components/website/web/HowItWorks";
import Pricing from "./components/website/web/Pricing";

import React from "react";

function Website() {
    function SiteRoutes() {
        return (
            <Routes>
                <Route path="/blog" element={<DesktopHomepage />} />
                <Route path="/how-it-works" element={<HowItWorks />} />
                <Route path="/pricing" element={<Pricing />} />
            </Routes>
        );
    }

    return <SiteRoutes />;
}

export default Website;
