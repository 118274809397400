import React from "react";
import laptop_mockup from "../../../../assets/website/website_computer_image.svg";
import { useNavigate } from "react-router-dom";

const Hero = () => {
    const navigate = useNavigate();
    return (
        <div className="hero-container">
            <div className="hero-text">
                <h1>Score Higher, Stress Less</h1>
                <h1 style={{ color: "#003169" }}>Master the SAT with Alia</h1>
                <p>
                    Experience personalized study plans, expert tutoring, and
                    adaptive practice exams designed to take your score to the
                    next level.
                </p>
                <button
                    className="apply-button"
                    onClick={() => navigate("/apply")}
                >
                    APPLY NOW
                </button>
            </div>
            <div className="hero-image">
                <img src={laptop_mockup} alt="Alia SAT study platform" />
            </div>
        </div>
    );
};

export default Hero;
