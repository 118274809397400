import React, { useState } from "react";
import right_arrow from "../../../../assets/website/right_arrow.svg";
import five_stars from "../../../../assets/website/five_stars.svg";
import left_arrow from "../../../../assets/website/left_arrow.svg";
import { useNavigate } from "react-router-dom";

const Reviews = () => {
    const navigate = useNavigate();
    const [reviewIndex, setReviewIndex] = useState(0);
    const reviews = [
        {
            text: "“I took the SAT twice last year and got a 1410 then 1420. I was feeling pretty stuck and didn’t know how to get my score to move. Alia made it simple for me to practice the harder concepts I needed to master for the test - it was the most similar to what I saw on test day. I just hit a 1520 and now I can focus on my college apps :). You have to put in the work, but they make everything else so easy.”",
            author: "Sarah M.",
        },
        {
            text: "Lorem ipsum 1",
            author: "Terry 1",
        },
        {
            text: "Lorem ipsum 2",
            author: "Terry 2",
        },
    ];

    const incrementIndex = () => {
        setReviewIndex((prevIndex) => (prevIndex + 1) % reviews.length);
    };

    const decrementIndex = () => {
        setReviewIndex(
            (prevIndex) => (prevIndex - 1 + reviews.length) % reviews.length,
        );
    };

    return (
        <div className="as-container" style={{ backgroundColor: "#016dea" }}>
            <div className="as-content">
                <div style={{ paddingBottom: "50px" }}>
                    <img src={five_stars} alt="Five stars" />
                </div>
                <div className="review-middle">
                    <div className="review-arrow-container">
                        <img
                            src={left_arrow}
                            alt="Left arrow"
                            className="review-arrow"
                            onClick={() => decrementIndex()}
                        />
                    </div>
                    <div className="review-middle-text">
                        <div className="as-title">
                            Don&apos;t Just Take Our Word For It
                        </div>
                        <div
                            className="as-text"
                            style={{ paddingBottom: "40px" }}
                        >
                            {reviews[reviewIndex].text}
                        </div>
                        <div className="as-text">
                            — {reviews[reviewIndex].author}
                        </div>
                    </div>
                    <div className="review-arrow-container">
                        <img
                            src={right_arrow}
                            alt="Left arrow"
                            className="review-arrow"
                            onClick={() => incrementIndex()}
                        />
                    </div>
                </div>

                <button
                    className="apply-button"
                    style={{
                        color: "#003169",
                        backgroundColor: "#FFAB24",
                    }}
                    onClick={() => navigate("/apply")}
                >
                    APPLY NOW
                </button>
            </div>
        </div>
    );
};

export default Reviews;
