import React, { useState } from "react";
import "../Home.css";
import { ChevronDown, ChevronUp } from "lucide-react";
import pset_image from "../../../../assets/website/pset_categories.png";
import sample_question from "../../../../assets/website/sample_question.png";
import settings_png from "../../../../assets/website/settings.png";
import { useNavigate } from "react-router-dom";

const Advantage = () => {
    const navigate = useNavigate();
    const [openDropdown, setOpenDropdown] = useState(null);

    const toggleDropdown = (index) => {
        setOpenDropdown(openDropdown === index ? null : index);
    };

    const advantageItems = [
        {
            title: "1:1 TUTORING AND SUPPORT",
            content:
                "Each student benefits from the dedicated attention of a real expert who checks in regularly, offering both emotional and objective support. This personal connection is the cornerstone of our service, providing the scaffolding you need to excel in your exams.",
        },
        {
            title: "CUSTOMIZED CONTENT",
            content:
                "Our content is tailored to meet your specific needs and learning style.",
        },
        {
            title: "SESSION MAXIMIZATION",
            content:
                "We ensure that every session is optimized for maximum learning and retention.",
        },
        {
            title: "INSIGHTFUL ANALYTICS",
            content:
                "Gain valuable insights into your progress and areas for improvement.",
        },
    ];

    return (
        <div className="as-container">
            <div className="as-content">
                <div className="as-title">Experience The Alia Advantage</div>

                <div className="as-middle">
                    <div className="as-left-container hide-on-mobile-1000">
                        <div className="as-image-layout">
                            <div className="as-image-container">
                                <img
                                    src={pset_image}
                                    alt="PSET image"
                                    className="as-tall-image"
                                />
                            </div>
                            <div className="as-image-stack">
                                <div className="as-image-container">
                                    <img
                                        src={sample_question}
                                        alt="Sample question"
                                        className="as-short-image"
                                    />
                                </div>
                                <div className="as-image-container">
                                    <img
                                        src={settings_png}
                                        alt="Settings"
                                        className="as-short-image"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="as-right-container">
                        {advantageItems.map((item, index) => (
                            <div key={index} className="as-dropdown">
                                <div
                                    className="as-dropdown-header"
                                    onClick={() => toggleDropdown(index)}
                                >
                                    <div className="as-dropdown-text">
                                        {item.title}
                                    </div>
                                    {openDropdown === index ? (
                                        <ChevronUp size={32} color="#EC5725" />
                                    ) : (
                                        <ChevronDown
                                            size={32}
                                            color="#FFAB24"
                                        />
                                    )}
                                </div>
                                {openDropdown === index && (
                                    <div className="as-text">
                                        {item.content}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>

                <button
                    className="apply-button"
                    onClick={() => navigate("/apply")}
                >
                    APPLY NOW
                </button>
            </div>
        </div>
    );
};

export default Advantage;
