// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero-container {
    display: flex;
    width: 95%;
    padding-top: 50px;
    max-width: 1600px;
    z-index: 10;
}

.hero-text {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-right: 2rem;
    max-width: 768px;
}

.hero-text h1 {
    color: #016DEA;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    margin: 0px;
    font-size: 28px;
}

.hero-text p {
    color: #565656;
    font-family: "DM Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 31.4px; /* 174.444% */
    text-align: left;
}


.hero-image {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.hero-image img {
  max-width: 100%;
  height: auto;
}


@media (min-width: 800px) and (max-width: 1600px) {

    .hero-text h1 {
        /* Linear scaling between 800px and 1600px viewport width */
        font-size: calc(28px + (56 - 28) * ((100vw - 800px) / (1600 - 800)));
    }
}

@media (min-width: 1601px) {
    .hero-text h1 {
        /* Fixed size for screens above 1600px */
        font-size: 56px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/website/web/sections/Hero.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,UAAU;IACV,iBAAiB;IACjB,iBAAiB;IACjB,WAAW;AACf;;AAEA;EACE,SAAO;EACP,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,uBAAuB;EACvB,mBAAmB;IACjB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,sBAAsB;IACtB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB,EAAE,aAAa;IAClC,gBAAgB;AACpB;;;AAGA;EACE,SAAO;EACP,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,YAAY;AACd;;;AAGA;;IAEI;QACI,2DAA2D;QAC3D,oEAAoE;IACxE;AACJ;;AAEA;IACI;QACI,wCAAwC;QACxC,eAAe;IACnB;AACJ","sourcesContent":[".hero-container {\n    display: flex;\n    width: 95%;\n    padding-top: 50px;\n    max-width: 1600px;\n    z-index: 10;\n}\n\n.hero-text {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-start;\n  padding-right: 2rem;\n    max-width: 768px;\n}\n\n.hero-text h1 {\n    color: #016DEA;\n    font-family: \"DM Sans\";\n    font-style: normal;\n    font-weight: 400;\n    margin: 0px;\n    font-size: 28px;\n}\n\n.hero-text p {\n    color: #565656;\n    font-family: \"DM Sans\";\n    font-size: 18px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 31.4px; /* 174.444% */\n    text-align: left;\n}\n\n\n.hero-image {\n  flex: 1;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: relative;\n}\n\n.hero-image img {\n  max-width: 100%;\n  height: auto;\n}\n\n\n@media (min-width: 800px) and (max-width: 1600px) {\n\n    .hero-text h1 {\n        /* Linear scaling between 800px and 1600px viewport width */\n        font-size: calc(28px + (56 - 28) * ((100vw - 800px) / (1600 - 800)));\n    }\n}\n\n@media (min-width: 1601px) {\n    .hero-text h1 {\n        /* Fixed size for screens above 1600px */\n        font-size: 56px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
