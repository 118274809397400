import "../Home.css";
import React from "react";
import { useNavigate } from "react-router-dom";

const Everything = () => {
    const navigate = useNavigate();
    const features = [
        {
            title: "CURATED CONTENT",
            description:
                "Experience a personalized learning journey with content that is tailored to your unique needs and goals.",
        },
        {
            title: "COLLEGE BOARD-STYLE ADAPTIVE EXAMS",
            description:
                "Practice with exams that adapt to your skill level, helping you prepare effectively and efficiently.",
        },
        {
            title: "QUESTIONS WITH SELECTABLE DIFFICULTY",
            description:
                "Choose the difficulty of questions to challenge yourself and improve progressively.",
        },
        {
            title: "SUMMARY AND STRATEGY SHEETS FOR MISTAKES",
            description:
                "Learn from your errors with detailed summaries and strategies to ensure you don't repeat them.",
        },
        {
            title: 'TIMING AND "PROBLEM AREA" TARGETED FOCUS',
            description:
                "Sharpen your skills with a focus on improving timing and addressing specific problem areas.",
        },
        {
            title: "MODULE 2 INTENSIVE TRAINING",
            description:
                "Tackle the toughest Reading/Writing and Math Module 2 problems with intensive training that's designed to conquer the seemingly impossible.",
        },
        {
            title: "PERSONIALIZED JOURNEY/GAME PLAN",
            description:
                "Stay focused and motivated with a strategic plan that keeps your goals in sight and your progress on track.",
        },
        {
            title: "WEEKLY OR BIWEEKLY CHECK-INS WITH AN EXPERT TUTOR",
            description:
                "Benefit from regular sessions with a dedicated tutor who will serve as your motivator, curator, and cheerleader.",
        },
    ];

    return (
        <div className="as-container" style={{ backgroundColor: "#FFF" }}>
            <div className="as-content">
                <div
                    className="as-title"
                    style={{ paddingBottom: "80px", color: "#016dea" }}
                >
                    Everything You Need to Ace the SAT
                </div>

                <div className="info-boxes-grid">
                    {features.map((feature, index) => (
                        <div key={index} className="info-box-grid">
                            <div className="info-content-grid">
                                <span className="info-header-grid">
                                    ✓ {feature.title}:
                                </span>
                                {feature.description}
                            </div>
                        </div>
                    ))}
                </div>

                <button
                    className="apply-button"
                    onClick={() => navigate("/apply")}
                >
                    APPLY NOW
                </button>
            </div>
        </div>
    );
};

export default Everything;
