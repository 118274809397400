import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./Navbar.css";
import alia_logo_blue from "../../../assets/alia_logo_blue.svg";
import alia_icon_blue from "../../../assets/alia_icon_blue.png";
import profile_icon from "../../../assets/profile_icon.svg";
import LogoutButton from "../auth/LogoutButton";
import { useSettings } from "./SettingsContext";

const Navbar = ({ setIsBlurred }) => {
    const navigate = useNavigate();
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [dropdownStyle, setDropdownStyle] = useState({});
    const [profileStyle, setProfileStyle] = useState({});
    const practiceRef = useRef(null); // Ref for the "PRACTICE" item
    const profileRef = useRef(null); // Ref for the "PRACTICE" item
    const [profileDropdown, setProfileDropdown] = useState(false);
    const { subscriptionBannerShown, setSubscriptionBannerShown } =
        useSettings();

    const handleMouseEnterPractice = () => {
        setProfileDropdown(false);
        setIsDropdownVisible(true);
        setIsBlurred(true); // Enable blur effect

        if (practiceRef.current) {
            const rect = practiceRef.current.getBoundingClientRect();
            setDropdownStyle({
                top: `${rect.bottom + 20}px`, // Position dropdown below the navbar item
                left: `${rect.left}px`, // Align dropdown with the left edge of the navbar item
                width: "100%", // Set your desired width or adjust as needed
            });
        }
    };

    const handleMouseLeave = () => {
        setIsDropdownVisible(false);
        setProfileDropdown(false);
        setIsBlurred(false); // Disable blur effect
    };

    const handleMouseEnterProfile = () => {
        setIsDropdownVisible(false);
        setProfileDropdown(true);
        setIsBlurred(true);

        if (profileRef.current) {
            const rect = profileRef.current.getBoundingClientRect();
            setProfileStyle({
                top: `${rect.bottom + 20}px`, // Position dropdown below the navbar item
                left: `${rect.left - 64}px`, // Align dropdown with the left edge of the navbar item
                width: "100%", // Set your desired width or adjust as needed
            });
        }
    };

    const closeBanner = () => {
        setSubscriptionBannerShown(false);
    };

    function SubscriptionBanner() {
        return (
            <div className="subscription-banner">
                <div className="subscription-banner-content">
                    <div className="subscription-banner-center">
                        <span>
                            Subscribe to Alia Premium for unlimited access to
                            our test prep content, customized for you
                        </span>
                        <button
                            className="upgrade-button"
                            onClick={() => navigate("/checkout")}
                        >
                            Upgrade Now
                        </button>
                    </div>
                    <button
                        className="close-button-banner"
                        onClick={closeBanner}
                    >
                        &times;
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="navbar-container">
            {subscriptionBannerShown && <SubscriptionBanner />}
            <div
                className={`navigation-bar ${isDropdownVisible || profileDropdown ? "dropdown-active" : ""}`}
                onMouseLeave={handleMouseLeave}
            >
                <div className="logo-container">
                    <Link to="/" onClick={handleMouseLeave}>
                        <picture>
                            <source
                                media="(max-width: 600px)"
                                srcSet={alia_icon_blue}
                            />
                            <img
                                src={alia_logo_blue}
                                alt="Logo"
                                style={{
                                    border: 0,
                                    maxWidth: 100,
                                    maxHeight: 50,
                                }}
                            />
                        </picture>
                    </Link>
                </div>
                <div className="navbar">
                    <div className="navbar-item-parent">
                        <div className="item-1">
                            <Link
                                to="/"
                                className="link"
                                onClick={handleMouseLeave}
                            >
                                HOME
                            </Link>
                        </div>
                        <div
                            className="item-1"
                            onMouseEnter={handleMouseEnterPractice}
                            ref={practiceRef}
                        >
                            <div className="nav-item-container">
                                <div className="dropdown-link">PRACTICE</div>
                            </div>
                        </div>
                    </div>
                    {isDropdownVisible && (
                        <DropdownPractice
                            style={dropdownStyle}
                            bannerShown={subscriptionBannerShown}
                        />
                    )}
                </div>
                <div className="profile-icon">
                    <img
                        alt="Group"
                        src={profile_icon}
                        onMouseEnter={handleMouseEnterProfile}
                        ref={profileRef}
                    />
                    {profileDropdown && (
                        <ProfileDropdown
                            style={profileStyle}
                            bannerShown={subscriptionBannerShown}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

const ProfileDropdown = ({ style, bannerShown }) => {
    const { settings } = useSettings();
    return (
        <div
            className="dropdown-background"
            style={{ top: bannerShown ? "140px" : "100px" }}
        >
            <div
                className="dropdown-content"
                style={{ ...style, position: "fixed" }}
            >
                <div
                    className="dropdown-button-test"
                    style={{ cursor: "default" }}
                >
                    <Link to="/settings" className="link">
                        My Account
                    </Link>
                </div>
                {["terence.m.conlon@gmail.com", "nrish07@gmail.com"].includes(
                    settings.userEmail,
                ) && (
                    <div
                        className="dropdown-button-test"
                        style={{ cursor: "default" }}
                    >
                        <Link to="/admin" className="link">
                            Admin
                        </Link>
                    </div>
                )}
                <LogoutButton />
            </div>
        </div>
    );
};

const DropdownPractice = ({ style, bannerShown }) => {
    const navigate = useNavigate();

    const navigateTo = (path) => {
        navigate(path);
    };

    return (
        <div
            className="dropdown-background"
            style={{ top: bannerShown ? "140px" : "100px" }}
        >
            <div
                className="dropdown-content"
                style={{ ...style, width: "100%", position: "fixed" }}
            >
                <div
                    className="dropdown-button-test"
                    onClick={() => navigateTo("/practice/tests")}
                >
                    Tests
                </div>
                <div
                    className="dropdown-button-test"
                    onClick={() => navigateTo("/practice/modules")}
                >
                    Modules
                </div>
                <div
                    className="dropdown-button-test"
                    onClick={() => navigateTo("/practice/problem-sets")}
                >
                    Problem Sets
                </div>
            </div>
        </div>
    );
};

export default Navbar;
