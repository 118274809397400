import React from "react";
import profile_icon from "../../../../assets/website/profile_icon_invert.svg";
import content_icon from "../../../../assets/website/alia_icon_checklist.svg";
import stopwatch from "../../../../assets/website/alia_icon_stopwatch.svg";
import chart from "../../../../assets/website/alia_icon_chart.svg";
import "./Features.css";
import { useNavigate } from "react-router-dom";

const Features = () => {
    const navigate = useNavigate();
    return (
        <div className="features-background">
            <div className="curve-wrapper">
                <div className="curved-background"></div>
            </div>
            <div className="features-content">
                <div className="features-icons">
                    <div className="feature-item">
                        <img src={profile_icon} alt="1:1 Tutoring & Support" />
                        <span>1:1 TUTORING & SUPPORT</span>
                    </div>
                    <div className="feature-item">
                        <img src={content_icon} alt="Customized Content" />
                        <span>CUSTOMIZED CONTENT</span>
                    </div>
                    <div className="feature-item">
                        <img src={stopwatch} alt="Session Maximization" />
                        <span>SESSION MAXIMIZATION</span>
                    </div>
                    <div className="feature-item">
                        <img src={chart} alt="Insightful Analytics" />
                        <span>INSIGHTFUL ANALYTICS</span>
                    </div>
                </div>
                <div className="feature-text-column">
                    <h2>Your All-in-one Destination for SAT Success</h2>
                    <p>
                        At Alia, we&apos;re a team of no-nonsense expert tutors
                        who specialize in delivering results. Our focus is on
                        the content that <em>truly</em> matters, ensuring that
                        every lesson is tailored to your unique needs. With
                        personalized lessons and questions, we guide you through
                        the intricacies of test preparation, maximizing your
                        time and effort for the best outcomes.
                    </p>
                    <button
                        className="apply-button"
                        style={{
                            color: "#003169",
                            backgroundColor: "#FFAB24",
                        }}
                        onClick={() => navigate("/apply")}
                    >
                        APPLY NOW
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Features;
