import React from "react";
import alia_logo from "../../../../assets/alia_logo_white.svg";
import { useNavigate } from "react-router-dom";

const ComparisonTable = () => {
    const navigate = useNavigate();
    const renderCell = (value) => {
        if (typeof value === "boolean") {
            return value ? (
                <span className="ct-icon-check"></span>
            ) : (
                <span className="ct-icon-x"></span>
            );
        }
        return value;
    };
    const studyFeatures = [
        "High-quality, official grade questions",
        "Questions that show up on the real test",
        "Customized learning path",
        "Content made just for you",
        "1-1 touchpoints with an expert tutor",
        "Desmos solutions and explanations",
        "Infinite content",
    ];

    const companies = [
        { name: "alia", features: [true, true, true, true, true, true, true] },
        {
            name: "BLUEBOOK / OFFICIAL RESOURCES",
            features: [true, true, false, false, false, false, false],
        },
        {
            name: "KHAN ACADEMY",
            features: [
                true,
                "sometimes",
                "sometimes",
                false,
                false,
                false,
                false,
            ],
        },
        {
            name: "TUTORING COMPANIES",
            features: [
                "sometimes",
                "sometimes",
                true,
                false,
                true,
                false,
                false,
            ],
        },
        {
            name: "PRINCETON REVIEW / KAPLAN",
            features: [false, false, false, false, false, "sometimes", false],
        },
    ];

    return (
        <div className="as-container" style={{ backgroundColor: "#FFF" }}>
            <div className="as-content" style={{ color: "#016dea" }}>
                <div className="as-title" style={{ color: "#016dea" }}>
                    Why Study With Alia?
                </div>
                <div className="ct-tables-container">
                    <table className="ct-study-features-side">
                        <thead>
                            <tr>
                                <th
                                    className="ct-feature-cell"
                                    style={{
                                        fontWeight: 400,
                                        fontSize: 46,
                                        fontStyle: "normal",
                                    }}
                                >
                                    Study Features
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {studyFeatures.map((feature, rowIndex) => (
                                <tr
                                    key={feature}
                                    className={
                                        rowIndex % 2 === 0
                                            ? "ct-even-row"
                                            : "ct-odd-row"
                                    }
                                >
                                    <td className="ct-feature-cell">
                                        {feature}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="ct-table-container">
                        <table className="ct-study-features-table">
                            <thead>
                                <tr>
                                    {companies.map((company, index) => (
                                        <th
                                            key={company.name}
                                            className="ct-company-column"
                                        >
                                            {index === 0 ? (
                                                <img
                                                    src={alia_logo}
                                                    alt={company.name}
                                                    className="ct-company-logo"
                                                />
                                            ) : (
                                                company.name
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {studyFeatures.map((feature, rowIndex) => (
                                    <tr
                                        key={feature}
                                        className={
                                            rowIndex % 2 === 0
                                                ? "ct-even-row"
                                                : "ct-odd-row"
                                        }
                                    >
                                        {companies.map((company) => (
                                            <td
                                                key={`${feature}-${company.name}`}
                                                className="ct-company-cell"
                                            >
                                                {renderCell(
                                                    company.features[rowIndex],
                                                )}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <button
                    className="apply-button"
                    onClick={() => navigate("/apply")}
                >
                    APPLY NOW
                </button>
            </div>
        </div>
    );
};

export default ComparisonTable;
