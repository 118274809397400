import { useJourney } from "../common/JourneyContext";
import React, { useEffect, useState } from "react";
import journey_icon from "../../../assets/journey_icon.svg";
import question_icon from "../../../assets/question_icon.svg";
import formatString from "../utils/TextProcessing";
import JourneyInfoModal from "../popups/JourneyInfoModal";
import JourneyPath from "./JourneyPath";
import ClickThroughButton from "../common/ClickThroughButton";

const MyJourney = () => {
    const { journey, journeyLoading } = useJourney();
    const [activeStep, setActiveStep] = useState(null);
    const [isInfoOpen, setIsInfoOpen] = useState(false);

    useEffect(() => {
        if (journey) {
            const currentStep = Object.values(journey).filter(
                (entry) => entry.current_step === true,
            );
            if (currentStep.length > 0) {
                setActiveStep(currentStep[0]);
            }
        }
    }, [journey]);

    function returnActiveStep(activeStep) {
        if (activeStep) {
            return (
                <>
                    <div className="active-step-details">
                        <div
                            className="step-specifics"
                            style={{ color: "#ec5725" }}
                        >
                            Current Step
                        </div>
                        <div className="step-header">
                            <div className="step-title">
                                {activeStep.step_name.split(",")[0]}
                            </div>
                            <ClickThroughButton
                                activeStep={activeStep}
                                className="step-button"
                                style={{ marginTop: "0px", maxWidth: "250px" }}
                            />
                        </div>
                        <div className="step-specifics">
                            <span>
                                Status:{" "}
                                {activeStep.status === "not_submitted"
                                    ? "Started"
                                    : formatString(activeStep.status)}
                            </span>
                            <span> |</span>
                            <span>
                                Progress: {activeStep.n_correct}/
                                {activeStep.n_total}
                            </span>
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <div className="active-step-details">
                    <div className="step-title" style={{ fontSize: "36px" }}>
                        No active step!
                    </div>
                </div>
            );
        }
    }

    return (
        <div className={"task-container"} style={{ marginTop: "0px" }}>
            <div className={"overlap"}>
                <div className="rectangle">
                    <button
                        onClick={() => setIsInfoOpen(true)}
                        className="info-button-style"
                    >
                        <img
                            className="question-icon"
                            src={question_icon}
                            alt="Question Icon"
                        />
                    </button>
                    <div className="task-header-group">
                        <img className="journey-icon" src={journey_icon} />
                        <div className="task-container-title">MY JOURNEY</div>
                    </div>
                </div>
                {journey && !journeyLoading ? (
                    <div
                        className="current-task-container"
                        style={{ justifyContent: "normal" }}
                    >
                        <div className="active-step-container">
                            {returnActiveStep(activeStep)}
                        </div>
                        <JourneyPath />
                    </div>
                ) : (
                    <div className="current-task-container">
                        <div className="task-title">Loading...</div>
                    </div>
                )}
                <JourneyInfoModal
                    isInfoOpen={isInfoOpen}
                    setIsInfoOpen={setIsInfoOpen}
                />
            </div>
        </div>
    );
};

export default MyJourney;
